import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {gsap} from 'gsap'
import * as dat from 'dat.gui'
/* import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js' */
import { sRGBEncoding } from 'three'


// ---------------- BASE -------------- //

// Debug
//const gui = new dat.GUI()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const video = document.querySelector( '#video' )
const embed = document.querySelector('#embed')


// -------------------- LOADERS ------------------- //

let sceneReady = false;
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
  // Loaded
  function(){
    console.log('loaded')
    window.setTimeout(function(){
      gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value:0})
    loadingBarElement.style.transform = ''
    loadingBarElement.classList.add('ended')
    }, 500)
    window.setTimeout(() =>
    {
        sceneReady = true;
    }, 3000)
  },
  // Progress
  function(itemUrl, itemsLoaded, itemsTotal){
    const progressRatio = itemsLoaded / itemsTotal;
    loadingBarElement.style.transform = `scaleX(${progressRatio})`
    console.log(progressRatio)
  }
)
const gltfLoader = new GLTFLoader(loadingManager)
const textureLoader = new THREE.TextureLoader(loadingManager)

// ONLY USED FOR 360 BGS
//const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)


// Overlay for loader fade-in
const overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms: {
    uAlpha: { value: 1 }
  },
  vertexShader: `
  void main(){
    gl_Position = vec4(position, 1.0);
  }
  `,
  fragmentShader:`
  uniform float uAlpha;
  void main() {
    gl_FragColor = vec4( 0.0, 0.0, 0.0, uAlpha);
  }
  `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}



// ------------------------ MODELS AND BACKGROUND ----------------- //
gltfLoader.load(
    '/models/gallery_new_compressed_textures.glb',
    (gltf) =>
    {
        scene.add(gltf.scene)
        updateAllMaterials()
    }
)

scene.background = new THREE.Color(0x6c7c96)

// ----------------------- TV SCREEN SET UP ---------------------------//

// TEXTURES

const playButton = textureLoader.load(
  '/textures/controls/play.png'
)
const pauseButton = textureLoader.load(
  '/textures/controls/pause.png'
)
const choice1Button = textureLoader.load(
  '/textures/controls/choice1.png'
)
const choice2Button = textureLoader.load(
  '/textures/controls/choice2.png'
)
const choice1ButtonSelected = textureLoader.load(
  '/textures/controls/choice1-selected.png'
)
const choice2ButtonSelected = textureLoader.load(
  '/textures/controls/choice2-selected.png'
)


// ADD A SCREEN SURROUND
const tvGeometry = new THREE.BoxBufferGeometry(2.6,1.5,0.16, 1)
const tvMaterial = new THREE.MeshBasicMaterial({
  color: 0x080808
  
})

const tv = new THREE.Mesh(tvGeometry, tvMaterial)
tv.rotation.y = -(Math.PI / 2)
tv.position.set(7.6,2.17,0)
scene.add(tv)

// ADD VIDEO CONTROL PANEL
const controlGeometry = new THREE.PlaneBufferGeometry(0.3,0.3, 1)
const controlMaterial = new THREE.MeshBasicMaterial({
  map: playButton
})
controlMaterial.map.encoding = sRGBEncoding

const tvControl = new THREE.Mesh(controlGeometry, controlMaterial)
tvControl.name = 'tvControl'
tvControl.rotation.y = -(Math.PI / 2)
tvControl.position.set(7.6,1,1)
scene.add(tvControl)

// ADD VIDEO CHOICE1 PANEL
const choiceGeometry = new THREE.PlaneBufferGeometry(0.3,0.3, 1)
const choiceMaterial = new THREE.MeshBasicMaterial({
  map: choice1ButtonSelected
})
choiceMaterial.map.encoding = sRGBEncoding

const tvChoice = new THREE.Mesh(choiceGeometry, choiceMaterial)
tvChoice.name = 'tvChoice1'
tvChoice.rotation.y = -(Math.PI / 2)
tvChoice.position.set(7.6,1,-1)
scene.add(tvChoice)

// ADD VIDEO CHOICE2 PANEL
const choice2Geometry = new THREE.PlaneBufferGeometry(0.3,0.3, 1)
const choice2Material = new THREE.MeshBasicMaterial({
  map: choice2Button
})
choice2Material.map.encoding = sRGBEncoding

const tvChoice2 = new THREE.Mesh(choice2Geometry, choice2Material)
tvChoice2.name = 'tvChoice2'
tvChoice2.rotation.y = -(Math.PI / 2)
tvChoice2.position.set(7.6,1,-0.6)
scene.add(tvChoice2)

// ADD A VIDEO SCREEN

const texture = new THREE.VideoTexture( video);

const screenGeometry = new THREE.PlaneBufferGeometry(2.5,1.4,1)
const screenMaterial = new THREE.MeshBasicMaterial({
  map: texture
})
screenMaterial.map.encoding = sRGBEncoding
const screen = new THREE.Mesh(screenGeometry, screenMaterial)
screen.position.set(7.5, 2.17,0)
screen.rotation.y = -(Math.PI / 2)

scene.add(screen)


// ------------------ LIGHTS ------------------- //

var hemisphereLight = new THREE.HemisphereLight(0xFFF7D3, 0x807c78, 3.2);
scene.add( hemisphereLight );
 
//gui.add(hemisphereLight, 'intensity').min(0).max(5).step(0.01).name('hemisphere intensity')

/* const directionalLight = new THREE.DirectionalLight('#ffffff', 15)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.width = 1024*3;
directionalLight.shadow.mapSize.height = 1024*3;
directionalLight.shadow.camera.far = 80
directionalLight.shadow.normalBias = -0.01
directionalLight.position.set(-15, 17, 9)
directionalLight.shadow.camera.top = 12
directionalLight.shadow.camera.left = -11
directionalLight.shadow.camera.right = 11
directionalLight.shadow.camera.bottom = -9
scene.add(directionalLight) */


/* gui.add(directionalLight.position, 'x').min(-15).max(15).step(0.5).name('light position x')
gui.add(directionalLight.position, 'y').min(3).max(30).step(0.5).name('light position y')
gui.add(directionalLight.position, 'z').min(-15).max(15).step(0.5).name('light position z')
gui.add(directionalLight, 'intensity').min(0).max(20).step(0.1).name('light intensity') */



// ------------------- SCREEN RESIZING ----------------- //

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



// --------------- RAYCASTER - CURRENTLY FOR VIDEO CONTROLS

const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()
window.addEventListener('click', function(event){
  mouse.x = event.clientX / sizes.width * 2 - 1
  mouse.y = -(event.clientY / sizes.height) * 2 + 1

  raycaster.setFromCamera(mouse, camera)
  var intersects = raycaster.intersectObjects(scene.children, true);
  const clickObject = intersects[0];
  
  console.log(clickObject);   
    
      if (clickObject != undefined) {

        // CHECK IF THE CLICKED OBJECT IS ANY OF THE VIDEO CONTROLS
        if (clickObject.object.name === "tvControl"){
          if (video.paused===true){
            video.play()
            controlMaterial.map = pauseButton
          } 
          else {
            video.pause()
            controlMaterial.map = playButton
          }
        }
        if (clickObject.object.name === "tvChoice1"){
          
          video.src = '/videos/vid_info.mp4'
          video.play()
          controlMaterial.map = pauseButton
          choiceMaterial.map = choice1ButtonSelected
          choice2Material.map = choice2Button

      }
        if (clickObject.object.name === "tvChoice2"){
         
            video.src = '/videos/vid_interview.mp4'
            video.play()
            controlMaterial.map = pauseButton
            choiceMaterial.map = choice1Button
            choice2Material.map = choice2ButtonSelected
        }
      }
    
})

// -------------------- CAMERA, CONTROLS AND RENDERER ------------------- //

// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-3, 2, 0);
camera.zoom = 1.3
camera.updateProjectionMatrix();
scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxDistance = 6.4
controls.minDistance = 0
controls.maxPolarAngle = Math.PI / 2 + 0.1
controls.minPolarAngle = Math.PI / 2 - 0.2
controls.target = new THREE.Vector3(0, 1.95, 0)
// TO INVERT THE CONTROLS UNCOMMENT THE LINE BELOW
//controls.rotateSpeed *= -1;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 2
/* renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap */
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/* // -------- POST PROCESSING - CURRENTLY NOT USED

let renderTargetClass = null

if (renderer.getPixelRatio() === 1  && renderer.capabilities.isWebGL2) {
  renderTargetClass = THREE.WebGLMultisampleRenderTarget
}
else {
  renderTargetClass = THREE.WebGLRenderTarget
}
const renderTarget = new renderTargetClass(
  800,
  600,
  {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    format: THREE.RGBAFormat,
    encoding: THREE.sRGBEncoding,
  }
)

// POST PROCESSING
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio), 2)
effectComposer.setSize(sizes.width, sizes.height)

// -------- PASSES
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

// -------- EFFECTS

const unrealBloomPass = new UnrealBloomPass()
unrealBloomPass.enabled = false
unrealBloomPass.strength = 0.26
unrealBloomPass.radius = 1
unrealBloomPass.threshold = 0.765
effectComposer.addPass(unrealBloomPass)
gui.add(unrealBloomPass, 'enabled').name('unreal bloom pass')
gui.add(unrealBloomPass, 'strength').min(0).max(1).step(0.005).name('unreal bloom strength')
gui.add(unrealBloomPass, 'radius').min(0).max(1).step(0.005).name('unreal bloom radius')
gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.005).name('unreal bloom threshold') */



// ------------------- HOTSPOTS ------------------ //

const modalOuter = document.querySelector('.modal-outer')
const modal = document.querySelector(".modal-inner")
const modalCloseButton = document.querySelector('#modal-close')
const modalContent = document.querySelector('#modal-content')

// Add Frustum camera for use with hotspots
const frustum = new THREE.Frustum();

// HOTSPOT DATA: MAP HTML HOTSPOTS TO 3D CO-ORDS AND ADD CONTENT
const points = [
  {
      position: new THREE.Vector3(-1.5, 1.85, -6.8),
      element: document.querySelector('.point-0'),
      name: 'point1',
      content: `<h2>Painting 1</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(1, 1.85, -6.8),
      element: document.querySelector('.point-1'),
      name: 'point2',
      content: `<h2>Painting 2</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(3.6, 1.85, -6.8),
      element: document.querySelector('.point-2'),
      name: 'point3',
      content: `<h2>Painting 3</h2>
                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(7.5, 1.85, -3.95),
      element: document.querySelector('.point-3'),
      name: 'point4',
      content: `<h2>Painting 4</h2>
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(7.5, 1.85, 3.2),
      element: document.querySelector('.point-4'),
      name: 'point5',
      content: `<h2>Painting 5</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(4.9, 1.85, 7.1),
      element: document.querySelector('.point-5'),
      name: 'point6',
      content: `<h2>Painting 6</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(2, 1.85, 7.1),
      element: document.querySelector('.point-6'),
      name: 'point7',
      content: `<h2>Painting 7</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  },
  {
      position: new THREE.Vector3(-0.9, 1.85, 7.1),
      element: document.querySelector('.point-7'),
      name: 'point8',
      content: `<h2>Painting 8</h2>
                <p>Lorem ipsum dolor ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <button>Cta</button>`
  }
]


// ------------------ HOTSPOT / MODAL CLICK FUNCTION

for(const point of points) {

  point.element.addEventListener('click', function(){
    var pointContent = point.content
    if (!modal.classList.contains('open')){
      modal.classList.add('open')

      setTimeout(function(){
        window.addEventListener('click', closeModalFromWindow)
      }, 500);
    }
    modalContent.innerHTML = pointContent
  }) 
}

// ------------------ CLOSE MODAL

modalCloseButton.addEventListener('click', function(){
  modal.classList.remove('open')
  window.removeEventListener('click', closeModalFromWindow)
})

function closeModalFromWindow() {
  if (event.target.className === 'webgl' || event.target.className === 'label'){
    modal.classList.remove('open')
    window.removeEventListener('click', closeModalFromWindow)
  }
}
// ------------------- ANIMATION LOOP

const tick = () =>
{
    // Update controls
    controls.update()

    if (sceneReady) {

      for(const point of points) {
        // MAKE A COPY OF POSITION VECTOR3
        const screenPosition = point.position.clone();
        // USE PROJECT TO CONVERT POSITION TO A 2D POSITION ON THE VIEWPORT (X AND Y BETWEEN -1 AND 1)
        screenPosition.project(camera)

        //camera.updateMatrix();
        //camera.updateMatrixWorld();
        frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));  
        
        if (frustum.containsPoint(point.position)) {
          // MAKE POINT VISIBLE AND MOVE IT ACCORDINGLY
          point.element.classList.add('visible')
          const translateX = screenPosition.x * sizes.width * 0.5
          const translateY = - screenPosition.y * sizes.height * 0.5
          point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        }
        else {
          point.element.classList.remove('visible')
        }
      }
    } 

    // Render
    renderer.render(scene, camera)
    //effectComposer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()